import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`;

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

const Background = styled.div`
    width: 100%;
    background: #fff9ef;
`;

const BigContainer = styled.div`
    padding-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
    padding-bottom: 10rem;
`;

const TitleText = styled.h1`
    font-family: 'NewKansas';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const Message = styled.div`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 4rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 20px;
    margin-bottom: 1rem;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

const Label = styled.div`
    font-family: 'NewKansas';
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 20px;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const EmailInput = styled.input`
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px;
    height: 35px;
    border-radius: 10px;
    border: 4px solid #d5d4c3;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    height: 46px;
    @media (max-width: 600px) {
        max-width: 600px;
    }
`;

const TextArea = styled.textarea`
  border: 4px solid #d5d4c3;
  resize: none;
  width: 100%;
  height: 10rem;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  z-index: 3;
  @media (max-width: 800px) {
    height: 6rem;
  }
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const Button = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 3rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: #F71735;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const SuccessPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: #14a336;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`;

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ImWorkingHereButton = styled.button`
  font-family: 'Poppins', sans-serif;
  width: 260px;
  height: 64px;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #fff;
  background: #fe814d;
  border: none;
  border-radius: 18px;
  box-sizing: border-box;
  display: flex;
  transition: all .2s ease-in-out;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
`;

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;


const Contact = () => {
    const [animate, setAnimate] = useState(false);
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [postSuccess, setPostSuccess] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);
    const [formIsValid, setFormIsValid] = useState(false);
    const [imWorkingHere, setImWorkingHere] = useState(false);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        document.title = 'Doodlebug | Contact'
    }, []);

    useEffect(() => {
        if (emailIsValid && comment) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
    }, [emailIsValid, comment]);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    const updateComment = (event) => {
        setComment(event.target.value);
    };

    const updateEmail = (event) => {
        setEmail(event.target.value);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(event.target.value).toLowerCase())) {
            setEmailIsValid(true);
        } else {
            setEmailIsValid(false);
        }
    };

    const sendMessage = async () => {
        if(formIsValid) {
            setImWorkingHere(true);
            const postUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/contact' : 'http://localhost:3001/api/contact';
            await fetch(postUrl, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    contactType: 'contact',
                    email: email,
                    message: comment
                })    
            })
            .then(res => {
                // setImWorkingHere(false);
                setPostSuccess(true);
                setEmail('');
                setComment('');
                setTimeout(() => {
                    setPostSuccess(false);
                }, 5000);
            })
            .catch(err => {
                console.error(err);
                setImWorkingHere(false);
                setShowErrorPopup(true);
                setTimeout(() => {
                    setShowErrorPopup(false);
                }, 3000);
            });
        } else {
            setShowInvalidPopup(true);
            setTimeout(() => {
                setShowInvalidPopup(false);
            }, 3000);
        }
    }

    return (
        <>
            <Background>
                <BigContainer animate = {animate}>
                    <TitleText>Contact</TitleText>
                    <Message>You can use the form below to ask a question, leave a comment, or rant about how the proportion of top US box office movies that are only sequels to or remakes of earlier movies has increased dramatically since 1990 (a symptom of cultural decay 😇). Doodlebug is happy to read your messaages. You can always reach a team member directly by emailing <MailLink href="mailto:support@doodlebug.me">support@doodlebug.me</MailLink>.</Message>
                    <Label>Email</Label>
                    <EmailInput value={email} onChange={updateEmail} placeholder="email@example.com"></EmailInput>
                    <Label>Message</Label>
                    <TextArea value={comment} onChange={updateComment} placeholder="Your message"></TextArea>
                    {imWorkingHere ? (
                        <ImWorkingHereButton><Dot/><Dot/><Dot/></ImWorkingHereButton>
                    ) : (
                        <Button onClick={sendMessage} isValid={formIsValid}><ButtonText>Submit</ButtonText></Button>
                    )}
                    {showErrorPopup && (
                        <ErrorPopup><PopupText>Something went wrong. If this issue persists, you can reach us directly at support@doodlebug.me.</PopupText></ErrorPopup>
                    )}
                    {showInvalidPopup && (
                        <ErrorPopup><PopupText>You need to input a valid email and message.</PopupText></ErrorPopup>
                    )}
                    {postSuccess && (
                        <SuccessPopup><PopupText>Success! Your message has been sent. Expect to hear back soon. You can navigate away from this page.</PopupText></SuccessPopup>
                    )}
                </BigContainer>
            </Background>
        </>
    )
}

export default Contact;
