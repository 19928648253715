import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

const goAway = keyframes`
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: .2;
      transform: scale(.95);
    }
`;

const Background = styled.div`
    width: 100%;
    background: #fff9ef;
`;

const BigContainer = styled.div`
    padding-top: 2.5rem;
    width: 62%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    animation: ${({ animate }) => (animate ? css`${goAway} 0.25s forwards` : 'none')};
    @media (max-width: 600px) {
        width: 85%;
    }
    padding-bottom: 10rem;
`;

const TitleText = styled.h1`
    font-family: 'NewKansas';
    font-size: 50px;
    margin-bottom: 20px;
    box-sizing: border-box;
    text-align: center;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
    @media (max-width: 600px) {
        font-size: 30px;
    }
`;

const MailLink = styled.a`
    font-family: 'Poppins', sans-serif;
    width: 100%;
    font-size: 18px;
    margin-bottom: 1rem;
    animation-delay: 0.6s;
    text-decoration: none;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

const Button = styled.button`
  width: 260px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #fe814d;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 3rem;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: #fe814d;
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 24px;
  @media (max-width: 600px) {
    font-size: 20px;
    margin-bottom: 0rem;
  }
`;

const LinkToHome = styled(Link)`
    text-decoration: none;
    width: 50%;
    max-width: 800px;
`;

const QuestionBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: ${fadeIn} 0.5s forwards;
    animation-delay: 0.2s;
    opacity: 0;
`;

const QuestionText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    @media (max-width: 600px) {
        font-size: 18px;
    }
`;

const AnswerBox  = styled.div`
    margin: 10px 10px;
`;

const AnswerText = styled.div`
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
`;

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #cccccc;
  margin: 10px 10px;
  animation: ${fadeIn} 0.5s forwards;
  animation-delay: 0.2s;
  opacity: 0;
`;

const FAQ = () => {
    const [animate, setAnimate] = useState(false);
    const [revealedAnswers, setRevealedAnswers] = useState([false, false, false, false, false])
    
    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        document.title = 'Doodlebug | FAQ'
      }, []);

    const handleGoHome = () => {
        setAnimate(true);
        const timer = setTimeout(() => {}, 500);
        return () => clearTimeout(timer);
    }

    const revealAnswer = (position) => {
        const newValue = revealedAnswers[position] === true ? false : true;
        const newRevealedAnswers = revealedAnswers.map((item, index) => index === position ? newValue : item);
        setRevealedAnswers(newRevealedAnswers);
    }

    return (
        <>
        <Background>
            <BigContainer animate = {animate}>
                <TitleText>FAQ</TitleText>
                <QuestionBox onClick = {() => revealAnswer(0)}>
                    <QuestionText>Do you do returns and/or refunds?</QuestionText>
                    {revealedAnswers[0] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[0] && (
                    <AnswerBox><AnswerText>Because your doodlebugs are completely unique, we do not accept returns (they can't be resold!). However, in the rare case that you receive product that is misprinted or otherwise of inferior quality, you are eligible for a refund or a reprint. Please contact <MailLink href="mailto:support@doodlebug.me">support@doodlebug.me</MailLink> to initiate that process.</AnswerText></AnswerBox>
                )}
                <Divider />
                <QuestionBox onClick = {() => revealAnswer(1)}>
                    <QuestionText>What's your shipping policy?</QuestionText>
                    {revealedAnswers[1] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[1] && (
                    <AnswerBox><AnswerText>Doodlebug ships through USPS first class. After placing the order, your order should arrive within 5 business days (most often in 3-4 days). After your order is printed and sent for delivery, you will receive a followup email with details to track your order. You can send an email to <MailLink href="mailto:support@doodlebug.me">support@doodlebug.me</MailLink> with any questions about your order.</AnswerText></AnswerBox>
                )}
                <Divider />
                <QuestionBox onClick = {() => revealAnswer(2)}>
                    <QuestionText>How does the doodlebug creation work? Is it AI?</QuestionText>
                    {revealedAnswers[2] ? (
                        <RemoveIcon />
                    ) : (
                        <AddIcon />
                    )}
                </QuestionBox>
                {revealedAnswers[2] && (
                    <AnswerBox><AnswerText>Yes, Doodlebug is built on open-source AI models and libraries. There are still many limitations to the technology. Make sure to check the images you generate for mistakes!</AnswerText></AnswerBox>
                )}
                <LinkToHome to="/" onClick={handleGoHome}>
                    <Button><ButtonText>Return Home</ButtonText></Button>
                </LinkToHome>
            </BigContainer>
        </Background>
        </>
    )
}

export default FAQ;
