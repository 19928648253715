import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0%, 49.999%, 100% {
    transform: none;
  }
  50%, 99.999% {
    transform: rotate(90deg);
  }
`;

const shiftLeft = keyframes`
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(-75%);
  }
`;

const shiftRight = keyframes`
  0%, 100% {
    transform: translateX(0%);
  }
  50% {
    transform: scale(0.65) translateX(75%);
  }
`;

const Container = styled.div`
  --uib-size: ${props => props.size || 40}px;
  --uib-color: ${props => props.color || 'black'};
  --uib-speed: .9s;
  position: relative;
  height: calc(var(--uib-size) / 2);
  width: var(--uib-size);
  filter: url('#uib-jelly-ooze');
  animation: ${rotate} calc(var(--uib-speed) * 2) linear infinite;
  will-change: transform;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 25%;
    width: 50%;
    height: 100%;
    background-color: var(--uib-color);
    border-radius: 100%;
    will-change: transform;
    transition: background-color 0.3s ease;
  }

  &::before {
    animation: ${shiftLeft} var(--uib-speed) ease infinite;
  }

  &::after {
    animation: ${shiftRight} var(--uib-speed) ease infinite;
  }
`;

const SVG = styled.svg`
  width: 0;
  height: 0;
  position: absolute;
`;

const JellyLoader = ({ size, color }) => {
  return (
    <>
      <Container size={size} color={color}/>
      <SVG width="0" height="0">
        <defs>
          <filter id="uib-jelly-ooze">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="5"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="ooze"
            />
            <feBlend in="SourceGraphic" in2="ooze" />
          </filter>
        </defs>
      </SVG>
    </>
  );
};

export default JellyLoader;