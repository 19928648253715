import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import SavedDoodlebugsContext from '../contexts/SavedDoodlebugsContext';
import ContinuedWithoutSavingDoodlebugsContext from "../contexts/ContinuedWithoutSavingDoodlebugsContext";
import ItemsContext from '../contexts/ItemsContext';
import UserInfoContext from '../contexts/UserInfoContext';
import { ArrowClockwise, FloppyDisk, X } from '@phosphor-icons/react';
import Cookies from 'js-cookie';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import '../css/fonts.css';
import BouncyLoader from '../components/BouncyLoader';
import JellyLoader from '../components/JellyLoader';

const popupFadeOut = keyframes`
  from {
    opacity 1;
  }
  to {
    opacity: 0;
  }
`;

const pulse = keyframes`
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
`;

const Background = styled.div`
  width: 100%;
  background: #fff9ef;
`;

const Container = styled.div`
  padding-top: 2.5rem;
  width: 62%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
      width: 85%;
  }
  padding-bottom: 10rem;
`;

const Step1 = styled.div`
  margin-bottom: 4rem;
`;

const Step2 = styled(motion.div)`
  width: 100%
`;

const Label = styled.div`
  font-family: 'NewKansas';
  font-size: 32px;
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
  color: #222222;
`;

const Paragraph = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-bottom: 1.5rem;
  color: #222222;
`;


const Button = styled.button`
  width: 250px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
    transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const BottomButtonsContainer = styled.div`
  display: flex;
  align-items: center
  justify-content: center;
  gap: 2rem;
`;

const GenerateMoreButton = styled.button`
  width: 250px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#222' : '#888')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#222' : '#888')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    width: 230px;
  }
`

const SaveButton = styled.button`
  width: 250px;
  border: none;
  height: 64px;
  padding: 10px 20px;
  color: #fff9ef;
  background: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  transform: scale(1);
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    position: absolute;
    border-radius: 68% / 5%;
    background-color: ${({valid}) => (valid ? '#fe814d' : '#f3bba6')};
    top: -1px;
    bottom: -1px;
    right: 13px;
    left: 13px;
    z-index: -1;
  }
  &:hover {
      transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    width: 230px;
  }
`;

const ButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  font-size: 21px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const PromptAreaContainer = styled.div`
  background: #d5d4c3;
  border: solid #d5d4c3;
  border-width: 2px 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  border-radius: 19px;
  paddding: 4px;
`;

const SuggestionsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 5px 0px 6px 0px;
  background: #d5d4c3;
  border: none;
  border-bottom: none;
  border-radius: 15px 15px 0 0;O
`;

const SuggestionsContainer = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  border: none;
  border-bottom: none;
  white-space: nowrap;
  scrollbar-width: none;
  posistion: relative;
  opacity: ${({ isLoading }) => (isLoading ? '0.5' : '1')};
  transition: opacity 0.5s;
  &::-webkit-scrollbar {
    display: none;
  }
  
  padding-right: 50px;

  mask-image: linear-gradient(
    to right,
    black calc(100% - 60px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    black calc(100% - 60px),
    transparent 100%;
  )
`;

const Suggestion = styled(motion.button)`
  padding: 6px 12px;
  background: #fff9ef;
  border: none;
  border-radius: 12px 12px 3px 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #222222;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    transform: ${({valid}) => (valid ? 'scale(1.03)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  &:active {
    transform: ${({valid}) => (valid ? 'scale(.99)' : 'scale(1)')};
    transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    &:hover {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
    &:active {
      transform: scale(1);
      transition: all 0.05s ease-in-out;
    }
  }
`;

const RefreshButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  background: #fff9ef;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  z-index: 1;
  transition: transform 0.1s ease-in-out;
  padding: 1px 6px;

  > svg {
    width: 28px;
    height: 28px;
  }
`;

const TextArea = styled.textarea`
  resize: none;
  border: none;
  width: 100%;
  border-radius: 3px 3px 15px 15px;
  min-height: 100px;
  color: #222222;
  max-height: 500px;
  padding: 8px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  z-index: 3;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  };
`;

const MinimizedTextArea = styled.textarea`
  resize: none;
  border: 4px solid #d5d4c3;
  border-radius: 15px;
  width: 100%;
  color: #222222;
  padding: 8px;
  outline: none;
  font-size: 16px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #d5d4c3;
  z-index: 3;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  margin-bottom: .5rem;
`;

const MinimizedLabel = styled.div`
  font-family: 'NewKansas';
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
  color: #222222;
`;

// variants I've looked at for edit button color: #222222, #D8315B, #F71735
const EditButton = styled.button`
  width: 180px;
  border: none;
  height: 52px;
  padding: 10px 20px;
  color: #fff9ef;
  background: #F71735;
  border-radius: 18px;
  font-weight: bold;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  transform: scale(1);
  &::after {
    content: '';
    position: absolute;
    border-radius: 78% / 5%;
    background-color: #F71735;
    top: -1px;
    bottom: -1px;
    right: 18px;
    left: 18px;
    z-index: -1;
  }
  &:hover {
      transform: scale(1.03);
      transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 250px;
  }
`;

const EditButtonText = styled.div`
  font-family: 'NewKansas';
  text-align: center;
  padding-left: 10px;
  font-size: 21px;
  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 0rem;
  }
`;

const Dot = styled.div`
    animation: ${pulse} 1s infinite;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
`;

const ErrorPopup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background: #F71735;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const Popup = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #333;
  opacity: 1;
  color: white;
  z-index: 1000;
  border-radius: 20px;
  transition: opacity 1s ease-out;
  animation: ${popupFadeOut} 2s forward;
  width: 80%;
  max-width: 600px;
`

const PopupText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(175px, 1fr));
  gap: 10px;
  margin-bottom: 1.5rem;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const ImageContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
  border: 4px solid ${({selected}) => (selected ? '#727fc0' : '#d5d4c3 ')};
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.03);
  }
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    &:hover {
      transform: scale(1.00);
    }
  }
`;

const GridImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
`;

const LoginOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
`;

const LoginContainer = styled(motion.div)`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1001;
`;

const LoginTitle = styled.h2`
  font-family: 'NewKansas', sans-serif;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const LoginButton = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  background: #d5d4c3;
  color: #222222;
  border: none;
  border-radius: 18px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const ContinueWithoutSavingButton = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #222222;
  background: white;
  border: none;
  border-radius: 18px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  gap: 1rem;
  &:hover {
    transform: scale(1.03);
    transition: all 0.05s ease-in-out;
  }
  &:active {
      transform: scale(.99);
      transition: all 0.05s ease-in-out;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  padding: 10px;
  border-radius: 50%;
  &:hover {
    background-color: #d5d4c3;
  }
  transition: background-color 0.2s;  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
`;

const SVGContainer = styled.div`
  height: 1.5em;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  svg {
    height: 100%;
    width: auto;
  }
`;

const IconHeaderContainer = styled.div`
  border: none;
  border-radius: 50%;
  background: #fcc7c1;
  height: 52px;
  width: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  margin-left: auto;
  margin-right: auto;
`;

const BouncyLoaderContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  margin-bottom: 1rem;
`;

const LoaderImage = styled(motion.img)`
  height: 100px;
  position: absolute;
  left: ${({ position }) => position === 'left' ? '0%' : position === 'center' ? '50%' : '100%'};
  transform: ${({ position }) => position === 'center' ? 'translateX(-50%)' : position === 'right' ? 'translateX(-100%)' : '0'};
`;

const LoaderStatus = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #222222;
  text-align: center;
`;

const StatusText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #d5d4c3;
  margin-bottom: 1rem;
`;

const AnimatedDots = styled.span`
  display: inline-block;
  width: 24px;
  &::after {
    content: '';
    animation: dots 1.5s steps(4, end) infinite;
  }

  @keyframes dots {
    0%, 20% { content: ''; }
    40% { content: '.'; }
    60% { content: '..'; }
    80%, 100% { content: '...'; }
  }
`;

const GenerateButtonsConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 18px;
`;


const Customize = () => {
    const { savedDoodlebugs, setSavedDoodlebugs } = useContext(SavedDoodlebugsContext);
    const { continuedWithoutSavingDoodlebugs, setContinuedWithoutSavingDoodlebugs } = useContext(ContinuedWithoutSavingDoodlebugsContext);
    const [prompt, setPrompt] = useState('');
    const { items, setItems } = useContext(ItemsContext);
    const { userInfo, setUserInfo } = useContext(UserInfoContext);
    const [imWorkingHere, setImWorkingHere] = useState(false);
    const imWorkingHereRef = useRef(imWorkingHere);
    const navigate = useNavigate();
    const [showStep2, setShowStep2] = useState(false);
    const [showIdeaOptions, setShowIdeaOptions] = useState(false);
    const [newDoodlebugs, setNewDoodlebugs] = useState([]);
    const [selectedDoodlebugs, setSelectedDoodlebugs] = useState([]);
    const [minimizeStep1, setMinimizeStep1] = useState(false);
    const [wsConnection, setWsConnection] = useState(null);
    const [estimatedWaitTime, setEstimatedWaitTime] = useState(null);

    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [containerHeight, setContainerHeight] = useState('auto');
    const minimizedRef = useRef(null);
    const expandedRef = useRef(null);
    const expandedTextAreaRef = useRef(null);

    const [generationProgress, setGenerationProgress] = useState(1);
    const [imagesPreloaded, setImagesPreloaded] = useState(false);
    const [basicPrompts, setBasicPrompts] = useState(
      {
        "animals": [
            "shiba inu",
            "panda",
            "bunny",
            "alpaca",
            "penguin",
            "calico cat",
            "hamster",
            "baby seal",
            "fox",
            "dolphin",
            "koala",
            "ducky",
            "otter",
            "capybara",
            "blue whale",
            "beagle",
            "parrot",
            "sleepy sloth",
            "honeybee",
            "polar bear",
            "frog",
            "dinosaur",
            "turtle",
            "cow",
            "songbird",
            "owl",
            "dragonfly",
            "jellyfish",
            "ladybug"
        ],
        "people": [
            "chef",
            "wizard",
            "magician",
            "ballerina",
            "luau dancer",
            "firefighter"
        ],
        "plants": [
            "cactus",
            "mushroom",
            "happy sunflower",
            "strawberry",
            "peaches",
            "red apple",
            "white lily",
            "avocado",
            "fire flower",
            "lemon",
            "pineapple",
            "melon",
            "venus fly trap"
        ],
        "yummy": [
            "cupcake",
            "onigiri",
            "cotton candy",
            "gingerbread man",
            "dumpling",
            "ice cream cone",
            "candy",
            "cookie",
            "apple pie",
            "lollipop",
            "lemonade"
        ],
        "misc": [
            "the cosmic void",
            "rainbow",
            "fluffy cloud",
            "the country of Italy",
            "shooting star",
            "UFO",
            "floating island",
            "the country of Brazil",
            "star",
            "black hole",
            "balloons",
            "spooky ghost",
            "Jupiter",
            "Saturn",
            "Ohio"
        ]
      }
    );
    const [suggestions, setSuggestions] = useState([]);
    const [addOrReplace, setAddOrReplace] = useState('replace');
    const step2Ref = useRef(null);

    const preloadImages = async (urls) => {
      const loadImage = url => {
          return new Promise((resolve, reject) => {
              const img = new Image();
              img.src = url;
              img.onload = () => resolve(url);
              img.onerror = () => reject(url);
          });
      };

      try {
          await Promise.all(urls.map(loadImage));
          setImagesPreloaded(true);
      } catch (error) {
          console.error('Failed to preload some images:', error);
          setImagesPreloaded(true);
      }
    };

    const getRandomSuggestions = (promptsObject, count) => {
      // Always get one random animal
      const randomAnimal = promptsObject.animals[Math.floor(Math.random() * promptsObject.animals.length)];
      
      // Create array of all other categories
      const otherCategories = ['plants', 'people', 'yummy', 'misc'];
      
      // Get remaining items from random categories (no duplicates)
      const remainingItems = [];
      const usedCategories = new Set();
      
      while (remainingItems.length < count - 1 && usedCategories.size < otherCategories.length) {
        // Pick a random category we haven't used yet
        const availableCategories = otherCategories.filter(cat => !usedCategories.has(cat));
        const randomCategory = availableCategories[Math.floor(Math.random() * availableCategories.length)];
        
        // Get random item from that category
        const categoryItems = promptsObject[randomCategory];
        const randomItem = categoryItems[Math.floor(Math.random() * categoryItems.length)];
        
        remainingItems.push(randomItem);
        usedCategories.add(randomCategory);
      }
      
      return [randomAnimal, ...remainingItems];
    };

    const updateSuggestions = async (currentPrompt, mode) => {
      if (prompt.trim() === '') {
        setSuggestions(getRandomSuggestions(basicPrompts, 4));
        setAddOrReplace('replace');
        return;
      }

      setIsLoadingSuggestions(true);

      try {
          const apiDomain = process.env.REACT_APP_ENVIRONMENT === 'production' 
              ? 'https://api.doodlebug.me' 
              : 'http://localhost:3001';

          const response = await fetch(`${apiDomain}/prompt-suggestions`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ prompt: prompt, mode: mode })
          });

          if (!response.ok) throw new Error('Failed to fetch suggestions');

          const data = await response.json();
          setSuggestions(data.suggestions);
          setAddOrReplace('add');
      } catch (error) {
          console.error('Error fetching suggestions:', error);
          // Fallback to basic prompts on error
          setSuggestions(getRandomSuggestions(basicPrompts, 4));
          setAddOrReplace('replace');
      } finally {
        setIsLoadingSuggestions(false);
      }
    };

    const implementSuggestion = (suggestion) => {
      if (addOrReplace === 'add') {
        const trimmedPrompt = prompt.trim();
        const trimmedSuggestion = suggestion.trim();
        
        // If prompt is empty, just use the suggestion
        if (!trimmedPrompt) {
          setPrompt(trimmedSuggestion);
          return;
        }
    
        // Check last character of prompt
        const lastChar = trimmedPrompt[trimmedPrompt.length - 1];
        
        // Define separators that don't need additional punctuation
        const separators = [',', ';', 'and', '&'];
        const endsWithSeparator = separators.some(sep => 
          trimmedPrompt.toLowerCase().endsWith(sep.toLowerCase())
        );
    
        // Add appropriate joining text
        let joinText;
        if (endsWithSeparator) {
          joinText = ' '; // Just add a space if we already have a separator
        } else if (lastChar === '.') {
          joinText = ' '; // Start fresh after a period
        } else {
          joinText = ', '; // Default case: add comma and space
        }
    
        setPrompt(trimmedPrompt + joinText + trimmedSuggestion);
      } else {
        setPrompt(suggestion);
      }
    };

      // this makes it so that when we hit 'enter' in the textfield, we start a new batch.
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault();
          if (prompt.trim() !== '') {
              generateImage();
          }
      }
    };

    const getWaitTime = async () => {
      try {
        const apiDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me' : 'http://localhost:3001';
        const response = await fetch(`${apiDomain}/wait-time`);
        const data = await response.json();
        setEstimatedWaitTime(data.estimatedWaitSeconds + 19);
      } catch (error) {
        console.error('Error fetching wait time:', error);
        setEstimatedWaitTime(30);
      }
    };

    useEffect(() => {
      document.title = 'Doodlebug | Create Doodlebug';
    }, []);

    useEffect(() => {
      setEstimatedWaitTime(getWaitTime);
    }, []);

    useEffect(() => {
      let intervalId;
      if (estimatedWaitTime && estimatedWaitTime > 0) {
        intervalId = setInterval(() => {
          setEstimatedWaitTime(prev => Math.max(0, prev - 1));
        }, 1000);
      }
      return () => {
        if (intervalId) clearInterval(intervalId);
      }
    }, [estimatedWaitTime]);

    useEffect(() => {
      if (minimizeStep1 && minimizedRef.current) {
        setContainerHeight(minimizedRef.current.offsetHeight);
      } else if (expandedRef.current) {
        setContainerHeight(expandedRef.current.offsetHeight);
      }
    }, [minimizeStep1]);

    useEffect(() => {
      if (containerHeight === 'auto') {
        if (minimizeStep1 && minimizedRef.current) {
          setContainerHeight(minimizedRef.current.offsetHeight);
        } else if (expandedRef.current) {
          setContainerHeight(expandedRef.current.offsetHeight);
        }
      }
    }, [prompt]);

    // scroll to the top lol
    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    // updates the imWorkingHereRef when imWorkingHere changes
    useEffect(() => {
      imWorkingHereRef.current = imWorkingHere;
    }, [imWorkingHere]);

    useEffect(() => {
      return () => {
        if (wsConnection) {
          wsConnection.close();
        }
      };
    }, []);

    // updates prompt as user types
    const updatePrompt = (event) => {
      const newPrompt = event.target.value;
      setPrompt(newPrompt);

      if(newPrompt.trim() === "") {
        setSuggestions(getRandomSuggestions(basicPrompts, 4));
        setAddOrReplace('replace');
      }
    };

    useEffect(() => {
      setSuggestions(getRandomSuggestions(basicPrompts, 4));
    }, []);

    const setupWebSocket = (jobId) => {
      const wsProtocol = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'wss' : 'ws';
      const apiDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me' : 'http://localhost:3001';
      const ws = new WebSocket(`${wsProtocol}://${apiDomain.replace('https://', '').replace('http://', '')}/ws?jobId=${jobId}`);
      setWsConnection(ws);

      ws.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        handleWebSocketMessage(message);
      };

      ws.onerror = (error) => {
        // need to handle this visually for the user too
        if (document.visibilityState === 'visible') {
          console.error('WebSocket error:', error);
          setImWorkingHere(false);
          setShowErrorPopup(true);
          ws.close();
          setTimeout(() => {
              setShowErrorPopup(false);
          }, 3000);
        } else {
          console.log('websocket closed while backgrounded -- will retry on return');
          ws.close();
        }
      };
    };

    const handleWebSocketMessage = async (message, returning = false) => {
      if (!imWorkingHereRef.current) {
        return;
      }

      if (message.type === "DOODLEBUG_PROCESSING") {
        setGenerationProgress(2);
        if(!returning) {
          setEstimatedWaitTime(18);
        }
      }

      if (message.type === 'DOODLEBUG_READY') {
        setGenerationProgress(3);
        await preloadImages(message.data.urls);
        setNewDoodlebugs(prevDoodlebugs => {
          const newUrls = message.data.urls.filter(url => !prevDoodlebugs.includes(url));
          return newUrls.length > 0 ? [...prevDoodlebugs, ...newUrls] : prevDoodlebugs;
        });
        setImWorkingHere(false);
        if (wsConnection) wsConnection.close();
      } else if (message.type === 'DOODLEBUG_ERROR') {
        throw new Error(message.data.error);
      }
    };

    // gets new doodlebugs!!
    const generateImage = async () => {

      if (wsConnection) {
        wsConnection.close();
        setWsConnection(null);
      }

      setEstimatedWaitTime(getWaitTime);

      setNewDoodlebugs([]);
      setGenerationProgress(1);
      setSelectedDoodlebugs([]);
      setImWorkingHere(true);
      setMinimizeStep1(true);
      setShowStep2(true);

      try {
        const apiDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me' : 'http://localhost:3001';
        const response = await fetch(`${apiDomain}/createdoodlebugs?prompt=${encodeURIComponent(prompt.substring(0,1947))}&urgent=true`);
        const data = await response.json();

        if (!data.success) {
          // need to handle this visually for the user
          throw new Error(data.message || 'failed to queue generation');
        }

        const jobId = data.jobId;

        document.addEventListener('visibilitychange', async function handleVisibilityChange() {
          if (document.visibilityState === 'visible' && (!wsConnection || wsConnection.readyState !== WebSocket.OPEN)) {
            try {
              const statusResponse = await fetch(`${apiDomain}/finished-doodlebugs/${jobId}/status`);
              if (statusResponse.ok) {
                const statusData = await statusResponse.json();
                handleWebSocketMessage(statusData, true);
                document.removeEventListener('visibilitychange', handleVisibilityChange);
              } else {
                setupWebSocket(jobId);
              }
            } catch (error) {
              console.error('error checking job status', error);
            }
          }
        });


        setupWebSocket(jobId);
      
      } catch (error) {
        console.error('Generation failed:', error);
        setImWorkingHere(false);
        setShowErrorPopup(true);
        setTimeout(() => {
            setShowErrorPopup(false);
        }, 3000); 
      }

      // we may consider backup generations on replicate if the service is down, or if the queue is too long
      // maybe we can add an endpoint that checks the generation time, and if it's more than some number, we just send it to replicate
      // yes I like this idea :>

      // const generateUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/generateonreplicate?prompt=' : 'http://localhost:3001/api/generateonreplicate?prompt=';
      // const eventSource = new EventSource(generateUrl + encodeURIComponent(prompt.substring(0,1947)));
      // eventSourceRef.current = eventSource;
// 
      // eventSource.addEventListener('success', (event) => {
      //   try {
      //       const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
      //       const imageUrls = JSON.parse(event.data);
      //       Cookies.set('imageUrls', event.data, { expires: 7 , domain: cookieDomain });
      //       // Add new doodlebugs to existing ones
      //       setNewDoodlebugs(prevDoodlebugs => [...prevDoodlebugs, ...imageUrls]);
      //       setImWorkingHere(false);
      //   } catch (error) {
      //       console.log(error);
      //   }
      //   eventSource.close();
      // });
// 
      // eventSource.addEventListener('failure', (event) => {
      //     setImWorkingHere(false);
      //     setShowErrorPopup(true);
      //     eventSource.close();
      //     setTimeout(() => {
      //         setShowErrorPopup(false);
      //     }, 3000);
      // });
    };

    // return to editing step 1 inputs (prompt)
    const goBackToStep1 = () => {
      setMinimizeStep1(false);
    };

    const handleAnimationComplete = () => {
      if (expandedTextAreaRef.current) {
        expandedTextAreaRef.current.focus();
        expandedTextAreaRef.current.setSelectionRange(prompt.length, prompt.length);
      }
    };

    const openLogin = () => {
      setShowLoginPopup(true);
    };

    const closeLogin = () => {
      setShowLoginPopup(false);
    };

    // save the doodlebugs to our saved doodlebugs array, or prompt login if we are not logged in already
    const saveDoodlebugs = async () => {

      if (userInfo.status !== "authenticated") {
        openLogin();
      } else {
        // add to savedDoodlebugs optimistically, to make sure that it's done before we redirect.
        const originalSavedDoodlebugs = savedDoodlebugs.slice();
        let newSavedDoodlebugs = savedDoodlebugs.slice();
        for(let i = 0; i < selectedDoodlebugs.length; i++) {
          if (!newSavedDoodlebugs.includes(selectedDoodlebugs[i])) {
            newSavedDoodlebugs.push(selectedDoodlebugs[i]);
          }
        }
        setSavedDoodlebugs(newSavedDoodlebugs);

        try {
          const saveDoodlebugsUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/api/savedoodlebugs' : 'http://localhost:3001/api/savedoodlebugs';
          const response = await fetch(saveDoodlebugsUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'CSRF-Token': window.csrfToken
            },
            body: JSON.stringify({ doodlebugs: selectedDoodlebugs }),
            credentials: 'include'
          });
          const data = await response.json();

          if (response.ok) {
            navigate('/order/phone-case');
          } else if (data.triggerLogin) {
            setSavedDoodlebugs(originalSavedDoodlebugs);
            openLogin();
          } else {
            setSavedDoodlebugs(originalSavedDoodlebugs);
            throw new Error(data.message || 'Error saving doodlebugs');
          }
        } catch (error) {
          console.error('Save failed', error);
          setSavedDoodlebugs(originalSavedDoodlebugs);
        }
      }
    };

    // to login with google
    // we include in this request the destination and the doodlebugs to save, so that the login logic also handles the doodlebug saving.
    const googleLogin = async () => {
      const loginUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'https://api.doodlebug.me/prepare-google-auth' : 'http://localhost:3001/prepare-google-auth';
      const requestUrls = continuedWithoutSavingDoodlebugs.length > 0 ? [...new Set([...continuedWithoutSavingDoodlebugs, ...selectedDoodlebugs])] : selectedDoodlebugs;
      const response = await fetch(loginUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:  JSON.stringify({ destination: 'order/phone-case', urls: requestUrls, items: items }),
        credentials: 'include'
      });
      const data = await response.json();
      window.location.href = data.authUrl;
    };

    const continueWithoutSaving = () => {
      let newDoodlebugs = continuedWithoutSavingDoodlebugs.slice();
      for(let i = 0; i < selectedDoodlebugs.length; i++) {
        if (!newDoodlebugs.includes(selectedDoodlebugs[i])) {
          newDoodlebugs.push(selectedDoodlebugs[i]);
        }
      }
      setContinuedWithoutSavingDoodlebugs(newDoodlebugs);
      const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? '.doodlebug.me' : null;
      Cookies.set('continuedWithoutSavingDoodlebugs', JSON.stringify(newDoodlebugs), {  domain: cookieDomain });
      navigate("/order/phone-case");
    };

    function selectDoodlebug(url) {
      if (!selectedDoodlebugs.includes(url)) {
          const newSelectedDoodlebugs = selectedDoodlebugs.slice();
          newSelectedDoodlebugs.push(url);
          setSelectedDoodlebugs(newSelectedDoodlebugs);
      } else {
          const newSelectedDoodlebugs = selectedDoodlebugs.filter(value => value !== url);
          setSelectedDoodlebugs(newSelectedDoodlebugs);
      }
    };

    return (
          <Background>
            {showErrorPopup && (
              <ErrorPopup><PopupText>Generation failed. Our servers are experiencing high demand--please try again.</PopupText></ErrorPopup>
            )}
            <Container>
              <LayoutGroup>
                <motion.div layout>
                <AnimatePresence mode="wait">
                  
                    {minimizeStep1 ? (
                      <motion.div
                        key="step_1_minimized"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                      >
                        <Step1>
                          <MinimizedLabel>Describe your doodlebug</MinimizedLabel>
                          <MinimizedTextArea value={prompt} onChange={updatePrompt} onClick={goBackToStep1} readOnly></MinimizedTextArea>
                          <EditButton disabled={prompt.trim() === ''} valid={prompt.trim() !== ''} onClick={goBackToStep1}><EditButtonText>Edit</EditButtonText></EditButton>
                        </Step1>
                      </motion.div>
                    ) : (
                      <motion.div
                        key="step_1_expanded"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                        onAnimationComplete={handleAnimationComplete}
                      >
                        <Step1>
                          <Label>Describe your doodlebug</Label>
                          <Paragraph>Type in the box below. Your doodlebug can be anything—a cat, a chef, a candy, even the moon! You can also use the suggestions for inspiration.</Paragraph>
                          <PromptAreaContainer>
                            <SuggestionsBar>
                              <SuggestionsContainer isLoading={isLoadingSuggestions}>
                                <AnimatePresence mode="wait">
                                  <motion.div
                                    key={suggestions.join(',')}
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                    style={{ display: 'flex', gap: '8px' }}
                                  >
                                    {suggestions.map((suggestion, index) => (
                                        <Suggestion 
                                            key={`${suggestion}-${index}`}
                                            onClick={() => implementSuggestion(suggestion)}
                                        >
                                            {suggestion}
                                        </Suggestion>
                                    ))}
                                  </motion.div>
                                </AnimatePresence>
                              </SuggestionsContainer>
                              <RefreshButton onClick={() => !isLoadingSuggestions && updateSuggestions()} isLoading={isLoadingSuggestions}>
                                {isLoadingSuggestions ? (
                                  <JellyLoader size={20} color='#222' />
                                ) : (
                                  <ArrowClockwise size={28} color='#222' />
                                )}
                              </RefreshButton>
                            </SuggestionsBar>
                            <TextArea ref={expandedTextAreaRef} value={prompt} onChange={updatePrompt} onKeyDown={handleKeyDown} placeholder="a shiba inu"></TextArea>
                            </PromptAreaContainer>
                            <GenerateButtonsConatiner>
                              <Button disabled={prompt.trim() === ''} valid={prompt.trim() !== ''} onClick={generateImage}><ButtonText>Create Doodlebug</ButtonText></Button>
                            </GenerateButtonsConatiner>
                        </Step1>
                      </motion.div>
                    )}
                </AnimatePresence>
                </motion.div>
              <motion.div
                key="step_2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                layout
              >
                {showStep2 && (
                  <Step2 layout ref={step2Ref}>
                    <AnimatePresence mode="wait">
                    {imWorkingHere ? (
                      <motion.div
                        key="preparing"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                      >
                          <Label>
                            Preparing your{'\u00A0'}
                            <span style={{ whiteSpace: 'nowrap' }}>
                              doodlebugs<AnimatedDots />
                            </span>
                          </Label>
                        <Paragraph>
                          {
                            typeof estimatedWaitTime === 'number'
                              ? estimatedWaitTime === 0
                                ? 'Almost ready...'
                                : `Wait time: ${estimatedWaitTime} second${estimatedWaitTime === 1 ? '' : 's'}`
                              : 'This usually takes 20 seconds'
                          }
                        </Paragraph>
                        <StatusText>
                          {generationProgress === 1 && '⏳  Waiting for available robot...'}
                          {generationProgress === 2 && '🎨  Creating doodlebugs...'}
                          {generationProgress === 3 && '📥  Downloading doodlebugs...'}
                        </StatusText>
                        <Paragraph></Paragraph>
                      </motion.div>
                    ): (
                      <motion.div
                        key="results"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        layout
                      >
                        <Label>Choose your favorites</Label>
                        <Paragraph>Press or click on a doodlebug to select it. You can keep as many as you like.</Paragraph>
                        <StyledGrid>
                          {newDoodlebugs.map((url) => (
                            <motion.div layout>
                              <ImageContainer key={url} onClick={() => selectDoodlebug(url)} selected={selectedDoodlebugs.includes(url)}>
                                <GridImage src={url} alt="Doodlebug" />
                              </ImageContainer>
                            </motion.div>
                          ))}
                        </StyledGrid>
                        {imWorkingHere && (
                          <BouncyLoaderContainer>
                            <BouncyLoader />
                          </BouncyLoaderContainer>
                        )}
                        <BottomButtonsContainer>
                          <GenerateMoreButton disabled={imWorkingHere || newDoodlebugs.length < 1} valid={!imWorkingHere && !newDoodlebugs.length < 1} onClick={generateImage}><ButtonText>Try Again</ButtonText></GenerateMoreButton>
                          <SaveButton disabled={selectedDoodlebugs.length < 1} valid={selectedDoodlebugs.length > 0} onClick={saveDoodlebugs}><ButtonText>Save & Order</ButtonText></SaveButton>
                        </BottomButtonsContainer>
                      </motion.div>
                    )}
                    </AnimatePresence>
                  </Step2>
                )}
              </motion.div>
              </LayoutGroup>
            </Container>
            <AnimatePresence>
              {showLoginPopup && (
                <LoginOverlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={closeLogin}
                >
                  <LoginContainer
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 0.8, opacity: 0 }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <IconHeaderContainer>
                        <FloppyDisk size={36} color="#222222" />
                    </IconHeaderContainer>
                    <LoginTitle>Connect an account to save your doodlebugs!</LoginTitle>
                    <LoginButton onClick={googleLogin}>
                        <SVGContainer>
                            <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
                                <g transform="translate(-1693 -927)">
                                    <circle transform="translate(1694 928)" cx="16" cy="16" r="16" fill="#fff"></circle>
                                    <path d="M1717.68 944.182a9.192 9.192 0 0 0-.145-1.636H1710v3.095h4.305a3.68 3.68 0 0 1-1.6 2.415v2.006h2.585a7.8 7.8 0 0 0 2.39-5.88z" fill="#4285f4" fillRule="evenodd"></path>
                                    <path d="M1710 952a7.638 7.638 0 0 0 5.294-1.938l-2.585-2.007a4.826 4.826 0 0 1-7.185-2.535h-2.673v2.073A8 8 0 0 0 1710 952z" fill="#34a853" fillRule="evenodd"></path>
                                    <path d="M1705.5 945.52a4.73 4.73 0 0 1 0-3.04v-2.073h-2.673a8.01 8.01 0 0 0 0 7.185l2.673-2.073z" fill="#fbbc05" fillRule="evenodd"></path>
                                    <path d="M1710 939.182a4.322 4.322 0 0 1 3.058 1.2l2.295-2.295A7.688 7.688 0 0 0 1710 936a8 8 0 0 0-7.149 4.407l2.673 2.073a4.768 4.768 0 0 1 4.476-3.3z" fill="#ea4335" fillRule="evenodd"></path>
                                </g>
                            </svg>
                        </SVGContainer>
                        Sign in with Google
                    </LoginButton>
                    <ContinueWithoutSavingButton onClick={continueWithoutSaving}>Continue without saving</ContinueWithoutSavingButton>
                    <CloseButton onClick={closeLogin}>
                        <X size={24} color="#222222"/>
                    </CloseButton>
                  </LoginContainer>
                </LoginOverlay>
              )}
            </AnimatePresence>
          </Background>
    );
};

export default Customize;
