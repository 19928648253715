import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { X } from '@phosphor-icons/react';

const QuizContainer = styled.div`
  width: 62%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2.5rem 0 10rem 0;
  @media (max-width: 600px) {
    width: 85%;
  }
  background: #fff9ef;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: #d5d4c3;
  border-radius: 4px;
  margin-bottom: 3rem;
`;

const Progress = styled.div`
  width: ${props => (props.progress * 100 / 5)}%;
  height: 100%;
  background: #fe814d;
  border-radius: 4px;
  transition: width 0.3s ease;
`;

const Question = styled.h2`
  font-family: 'NewKansas';
  font-size: 32px;
  color: #222222;
  margin-bottom: 2rem;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Option = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  padding: 1rem 1.5rem;
  border: 4px solid #d5d4c3;
  border-radius: 15px;
  background: white;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.02);
    border-color: #fe814d;
  }

  &:active {
    transform: scale(0.98);
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
`;

const BackButton = styled.button`
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #666;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: color 0.2s ease;
  margin-top: 1.5rem;

  &:hover {
    color: #fe814d;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const QuizOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff9ef;
  z-index: 1000;
  overflow-y: auto;
  &#quiz-overlay {
    scroll-behavior: smooth;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  margin: 1.2rem 1.2rem 0 auto;
  padding: 0;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        transform: scale(1.05);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    &:active {
        transform: scale(.99);
        transition: all 0.05s ease-in-out;
        z-index: 1;
    }
    @media (max-width: 600px) {
        &:hover {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
        &:active {
            transform: scale(1);
            transition: all 0.05s ease-in-out;
            z-index: 1;
        }
    }
`;

const questions = [
  {
    question: "Person, animal, plant, or object?",
    options: ["Person", "Animal", "Plant", "Object"]
  },
  {
    Person: {
      question: "Choose a music genre.",
      options: ["J-pop", "Rock", "Instrumental", "Country"]
    },
    Animal: {
      question: "Choose a music genre.",
      options: ["J-pop", "Rock", "Instrumental", "Country"]
    },
    Plant: {
      question: "Choose a music genre.",
      options: ["J-pop", "Rock", "Instrumental", "Country"]
    },
    Object: {
      question: "Choose a music genre.",
      options: ["J-pop", "Rock", "Instrumental", "Country"]
    }
  },
  {
    question: "Choose a snack.",
    options: ["Fresh fruit", "Protein shake", "Rice ball", "Candy bar"]
  },
  {
    question: "Choose an expression for your doodlebug.",
    options: [":)", ":O", ">:(", ":|"] // we may replace with just pictures or just emojis or smth
  },
  {
    question: "Choose a season.", // just need to figure out this last question. In the future we might use this for art style, if we have multiple artists. If not the artist, this question should be the random element that can change the flavor
    options: ["Spring", "Summer", "Fall", "Winter"]
  }
];


// every question should determine something concrete about our prompt, but the user should not immediately understand how (aside from question 1).
// let's follow these
//   person --> something that will indicate professional or activity category --> something to help specify within that category --> expression or mood question --> extra
//   animal --> something that will indicate animal habitat or general category --> something to help specify within that category --> expression or mood question --> extra
//   plant --> something that will indicate "plant" type (maybe this is like tree or shrub or potted or fungus idk) --> something to help specify within that category --> expression or mood question --> extra
//   object --> something that will indicate object type (man-made, cosmic/celestial, natural, something else) --> specify --> expression/mood --> extra

const Quiz = ({ onComplete }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const overlay = document.getElementById('quiz-overlay');
    if (overlay) {
      overlay.scrollTo({ top: 0 });
    }
  }, [currentQuestion]);

  const handleAnswer = (answer) => {
    const newAnswers = { ...answers, [currentQuestion]: answer };
    setAnswers(newAnswers);

    if (currentQuestion === questions.length - 1) {
      // TODO: define onComplete function
      onComplete(newAnswers);
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const getCurrentQuestion = () => {
    if (currentQuestion === 1) {
      return questions[1][answers[0]].question;
    }
    return questions[currentQuestion].question;
  };

  const navigateToHome = () => {
    navigate('/');
  }

  const getCurrentOptions = () => {
    if (currentQuestion === 1) {
      return questions[1][answers[0]].options;
    }
    return questions[currentQuestion].options;
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  return (
    <QuizOverlay id="quiz-overlay" layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <CloseButton onClick={navigateToHome}>
        <X size={32} color="#222222" />
      </CloseButton>
    <QuizContainer>
      <motion.div layout>
      <ProgressBar>
        <Progress progress={currentQuestion + 1} />
      </ProgressBar>
      </motion.div>
      
      <AnimatePresence mode="wait">
        <motion.div layout
          key={currentQuestion}
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -20 }}
          transition={{ duration: 0.3 }}
        >
          <Question>{getCurrentQuestion()}</Question>
          <OptionsContainer>
            {getCurrentOptions().map((option, index) => (
              <Option
                key={index}
                onClick={() => handleAnswer(option)}
              >
                {option}
              </Option>
            ))}
          </OptionsContainer>
          {currentQuestion > 0 && (
            <BackButton onClick={handleBack}>
              ← Go back
            </BackButton>
          )}
        </motion.div>
      </AnimatePresence>
    </QuizContainer>
    </QuizOverlay>
  );
};

export default Quiz;
